* {
    margin: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Ubuntu Light", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
}

.padding-small {
    padding: 10px 0;
    background-color: #ffffff !important;
}

.btn-nav {
    position: absolute;
    top: 50%;
    z-index: 2;
    background: transparent;
    outline: none;
    border: 1px solid black;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 2.5em;
    color: rgba(0, 0, 0);
    font-stretch: ultra-condensed;
    font-weight: 100;
}

.btn-nav.right {
    right: -75px;
}
.btn-nav.left {
    left: -75px;
}

.body-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.fluid-container {
    width: 95%;
    margin: 0 auto;
}

.header {
    margin: 0;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 6px 0px #ccc;
}

.logo {
    height: 80px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.header .links {
    padding-right: 20px;
    list-style: none;
}

.header .links li {
    display: inline;
    padding: 10px;
}
.header .links li a {
    text-decoration: none;
    color: black;
}

.header img {
    display: block;
    height: 80px;
}

.footer {
    min-height: 100px;
    background-color: #eeeeef;
    padding: 40px;
    text-align: start;
}

.footer p {
    font-size: 0.8rem;
    margin-bottom: 15px;
    font-family: monospace;
    color: #888;
    line-height: 1.5;
    letter-spacing: 0.2ch;
}

.footer .copyright {
    font-size: 0.8rem;
    color: #888;
    line-height: 1.5;
    letter-spacing: 0.2ch;
}

.hero {
    min-height: 20vh;
    background-color: #fefbfb;
    display: flex;
    justify-content: space-around;
    gap: 30px;
    margin-bottom: 60px;
}

.hero-text {
    flex: 0.3;
    padding: 20px 15px;
    color: rgb(245, 238, 238);
    text-align: start;
}

.hero-text h1 {
    position: relative;
    padding-bottom: 20px;
}

.hero-text h1::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    width: 30%;
    background-image: linear-gradient(to right, #6e45e1, #89d4cf);
}

.hero-text p {
    padding: 20px 0;
    letter-spacing: 0.1ch;
}

.hero-card {
    flex: 0.18;
    background-image: linear-gradient(to bottom, #fff, #89d4cf);
    transform: translateY(50px);
    box-shadow: 0px 0px 6px 0px #272b3d;
}

.tribe {
    font-size: 2rem;
    font-family: cursive;
    color: yellow;
}

.source-body {
    margin: 10px 0px;
    background-image: url("https://dj7u9rvtp3yka.cloudfront.net/layout/banners/promotions/ZilingoRevamp/Banner2-2p301-w2880p0-h1252p0-en_v6.jpg");
    background-size: cover;
}

.source-body .body-content {
    display: table;
    padding: 80px;
}

.body-content .body-heading {
    text-align: initial;
    padding: 5px;
    font-size: xx-large;
    font-family: sans-serif;
    font-weight: bold;
    color: darkcyan;
}

.body-content .body-para1 {
    padding: 5px;
    font-size: smaller;
    font-weight: 600;
    color: darkslategrey;
    text-align: initial;
}

.body-content .body-list {
    text-align: initial;
    font-size: revert;
    padding: 25px 23px;
}

.body-content .body-para2 {
    padding: 5px;
    text-align: initial;
    font-weight: 500;
}

.source-body .button-container {
    padding-bottom: 80px;
}

.source-body .button-container .body-button {
    display: table;
    background: black;
    border-radius: 0;
    left: 80px;
    padding-left: 40px;
    padding-right: 40px;
}

.buy-body {
    margin: 10px 0px;
    display: flex;
    justify-content: flex-end;
    background-image: url("https://dj7u9rvtp3yka.cloudfront.net/layout/banners/promotions/ZilingoRevamp/Banner3-2p301-w2880p0-h1252p0-en_v6.png");
    background-size: cover;
}

.buy-body .body-container {
    padding: 100px;
    padding-right: 180px;
}

.buy-body .body-container .body-content {
    padding-top: 40px;
    padding-bottom: 80px;
}

.buy-body .body-container .body-content .body-heading {
    color: orangered;
    text-align: initial;
    padding: 5px;
    font-size: xx-large;
    font-family: sans-serif;
    font-weight: bold;
}

.buy-body .body-container .body-content .body-para1 {
    padding: 5px;
    font-size: smaller;
    font-weight: 600;
    color: darkslategrey;
    text-align: initial;
}

.buy-body .body-container .body-content .body-para2,
.body-para3 {
    padding: 5px;
    padding-top: 10px;
    font-size: smaller;
    font-weight: 600;
    color: darkslategrey;
    text-align: initial;
}

.buy-body .body-container .button-container {
    text-align: initial;
    padding: 5px;
}

.buy-body .body-container .button-container .body-button {
    background: black;
    border-radius: 0;
    padding-left: 40px;
    padding-right: 40px;
}

.trade-body {
    margin: 10px 0px;
    display: flex;
    justify-content: flex-start;
    background-image: url("https://dj7u9rvtp3yka.cloudfront.net/layout/banners/promotions/ZilingoRevamp/Banner4-2p301-w2880p0-h1252p0-en_v7.png");
    background-size: cover;
}

.trade-body .body-container {
    padding: 155px;
    padding-right: 180px;
}

.trade-body .body-container .body-content .body-heading {
    color: purple;
    text-align: initial;
    padding: 5px;
    font-size: xx-large;
    font-family: sans-serif;
    font-weight: bold;
}

.trade-body .body-container .body-content {
    padding-bottom: 40px;
}

.trade-body .body-container .body-content .body-para1 {
    padding: 5px;
    padding-bottom: 30px;
    font-size: smaller;
    font-weight: 600;
    color: darkslategrey;
    text-align: initial;
}

.trade-body .body-container .body-content .body-para2,
.body-para3 {
    padding: 5px;
    padding-top: 10px;
    font-size: smaller;
    font-weight: 600;
    color: darkslategrey;
    text-align: initial;
}

.trade-body .body-container .button-container {
    text-align: initial;
    padding: 5px;
}

.trade-body .body-container .button-container .body-button {
    background: black;
    border-radius: 0;
    padding-left: 40px;
    padding-right: 40px;
}

.heroCarousal {
    position: relative;
    width: 100%;
    min-height: 80vh;
    margin-bottom: 5px;
}

.heroCarousal-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

/* For G-Connect Globe Section */
.animate-card {
    width: 100%;
    height: 100%;
    background: #09248b;
    position: relative;
    color: white;
    margin-bottom: 5px;
}

.animate-card::before {
    content: "";
    width: 102%;
    height: 105%;
    border-radius: 8px;
    background-image: linear-gradient(
        var(--rotate),
        #5ddcff,
        #3c67e3 43%,
        #4e00c2
    );
    position: absolute;
    z-index: -1;
    top: -2.5%;
    left: -1%;

    animation: spin 5s linear infinite;
}

.animate-card::after {
    position: absolute;
    content: "";
    top: 83px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    transform: scale(0.8);
    filter: blur(83px);
    background-image: linear-gradient(
        var(--rotate),
        #5ddcff,
        #3c67e3 43%,
        #4e00c2
    );

    animation: spin 5s linear infinite;
}

/* Registration Form */
.registration-container {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 20px 5px;
    background-color: #fafafa;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px #ddd;
}

.registration-container input,
.registration-container select {
    display: block;
    margin: 10px auto;
    min-height: 40px;
    padding-left: 10px;
    font-size: 1.1rem;
    min-width: 98%;
}

@keyframes spin {
    0% {
        --rotate: 0deg;
    }
    100% {
        --rotate: 360deg;
    }
}

@media screen and (max-width: 480px) {
    .heroCarousal {
        width: 90%;
        margin: auto;
        min-height: 100px;
        height: 80vh;
        display: flex;
    }
    .heroCarousal-container {
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
    .flex-col {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
    }
    .registration-container {
        overflow: hidden;
    }
    .registration-container input,
    .registration-container select {
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .heroCarousal-container {
        width: 90%;
        margin: auto;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
    .heroCarousal-container {
        width: 90%;
        margin: auto;
    }
}

/* Slider Button special media query */
@media screen and (min-width: 761px) and (max-width: 1350px) {
    .btn-nav.right {
        bottom: 50px;
        top: auto;
        right: 50px;
    }
    .btn-nav.left {
        bottom: 50px;
        top: auto;
        left: auto;
        right: 125px;
    }
}
