*{
  box-sizing: border-box;
}

html,body{
  margin: 0;
  overflow-x: hidden;
}

.image{
  display:block;
  width: 100%;
}